var ar = {};
module["exports"] = ar;
ar.title = "Arabic";
ar.separator = " & ";
ar.address = require("./address");
ar.phone_number = require("./phone_number");
ar.cell_phone = require("./cell_phone");
ar.commerce = require("./commerce");
ar.vehicle = require("./vehicle");
ar.team = require("./team");
