module["exports"] = {
  "UAE Dirham": {
    "code": "AED",
    "symbol": ""
  },
  "Afghani": {
    "code": "AFN",
    "symbol": "؋"
  },
  "Lek": {
    "code": "ALL",
    "symbol": "Lek"
  },
  "Armenian Dram": {
    "code": "AMD",
    "symbol": ""
  },
  "Netherlands Antillian Guilder": {
    "code": "ANG",
    "symbol": "ƒ"
  },
  "Kwanza": {
    "code": "AOA",
    "symbol": ""
  },
  "Argentine Peso": {
    "code": "ARS",
    "symbol": "$"
  },
  "Australian Dollar": {
    "code": "AUD",
    "symbol": "$"
  },
  "Aruban Guilder": {
    "code": "AWG",
    "symbol": "ƒ"
  },
  "Azerbaijanian Manat": {
    "code": "AZN",
    "symbol": "ман"
  },
  "Convertible Marks": {
    "code": "BAM",
    "symbol": "KM"
  },
  "Barbados Dollar": {
    "code": "BBD",
    "symbol": "$"
  },
  "Taka": {
    "code": "BDT",
    "symbol": ""
  },
  "Bulgarian Lev": {
    "code": "BGN",
    "symbol": "лв"
  },
  "Bahraini Dinar": {
    "code": "BHD",
    "symbol": ""
  },
  "Burundi Franc": {
    "code": "BIF",
    "symbol": ""
  },
  "Bermudian Dollar (customarily known as Bermuda Dollar)": {
    "code": "BMD",
    "symbol": "$"
  },
  "Brunei Dollar": {
    "code": "BND",
    "symbol": "$"
  },
  "Boliviano boliviano": {
    "code": "BOB",
    "symbol": "Bs"
  },
  "Brazilian Real": {
    "code": "BRL",
    "symbol": "R$"
  },
  "Bahamian Dollar": {
    "code": "BSD",
    "symbol": "$"
  },
  "Pula": {
    "code": "BWP",
    "symbol": "P"
  },
  "Belarussian Ruble": {
    "code": "BYR",
    "symbol": "p."
  },
  "Belize Dollar": {
    "code": "BZD",
    "symbol": "BZ$"
  },
  "Canadian Dollar": {
    "code": "CAD",
    "symbol": "$"
  },
  "Congolese Franc": {
    "code": "CDF",
    "symbol": ""
  },
  "Swiss Franc": {
    "code": "CHF",
    "symbol": "CHF"
  },
  "Chilean Peso": {
    "code": "CLP",
    "symbol": "$"
  },
  "Yuan Renminbi": {
    "code": "CNY",
    "symbol": "¥"
  },
  "Colombian Peso": {
    "code": "COP",
    "symbol": "$"
  },
  "Costa Rican Colon": {
    "code": "CRC",
    "symbol": "₡"
  },
  "Cuban Peso": {
    "code": "CUP",
    "symbol": "₱"
  },
  "Cuban Peso Convertible": {
    "code": "CUC",
    "symbol": "$"
  },
  "Cape Verde Escudo": {
    "code": "CVE",
    "symbol": ""
  },
  "Czech Koruna": {
    "code": "CZK",
    "symbol": "Kč"
  },
  "Djibouti Franc": {
    "code": "DJF",
    "symbol": ""
  },
  "Danish Krone": {
    "code": "DKK",
    "symbol": "kr"
  },
  "Dominican Peso": {
    "code": "DOP",
    "symbol": "RD$"
  },
  "Algerian Dinar": {
    "code": "DZD",
    "symbol": ""
  },
  "Kroon": {
    "code": "EEK",
    "symbol": ""
  },
  "Egyptian Pound": {
    "code": "EGP",
    "symbol": "£"
  },
  "Nakfa": {
    "code": "ERN",
    "symbol": ""
  },
  "Ethiopian Birr": {
    "code": "ETB",
    "symbol": ""
  },
  "Euro": {
    "code": "EUR",
    "symbol": "€"
  },
  "Fiji Dollar": {
    "code": "FJD",
    "symbol": "$"
  },
  "Falkland Islands Pound": {
    "code": "FKP",
    "symbol": "£"
  },
  "Pound Sterling": {
    "code": "GBP",
    "symbol": "£"
  },
  "Lari": {
    "code": "GEL",
    "symbol": ""
  },
  "Cedi": {
    "code": "GHS",
    "symbol": ""
  },
  "Gibraltar Pound": {
    "code": "GIP",
    "symbol": "£"
  },
  "Dalasi": {
    "code": "GMD",
    "symbol": ""
  },
  "Guinea Franc": {
    "code": "GNF",
    "symbol": ""
  },
  "Quetzal": {
    "code": "GTQ",
    "symbol": "Q"
  },
  "Guyana Dollar": {
    "code": "GYD",
    "symbol": "$"
  },
  "Hong Kong Dollar": {
    "code": "HKD",
    "symbol": "$"
  },
  "Lempira": {
    "code": "HNL",
    "symbol": "L"
  },
  "Croatian Kuna": {
    "code": "HRK",
    "symbol": "kn"
  },
  "Gourde": {
    "code": "HTG",
    "symbol": ""
  },
  "Forint": {
    "code": "HUF",
    "symbol": "Ft"
  },
  "Rupiah": {
    "code": "IDR",
    "symbol": "Rp"
  },
  "New Israeli Sheqel": {
    "code": "ILS",
    "symbol": "₪"
  },
  "Indian Rupee": {
    "code": "INR",
    "symbol": ""
  },
  "Bhutanese Ngultrum": {
    "code": "BTN",
    "symbol": "Nu"
  },
  "Indian Rupee": {
    "code": "INR",
    "symbol": "₹"
  },
  "Iraqi Dinar": {
    "code": "IQD",
    "symbol": ""
  },
  "Iranian Rial": {
    "code": "IRR",
    "symbol": "﷼"
  },
  "Iceland Krona": {
    "code": "ISK",
    "symbol": "kr"
  },
  "Jamaican Dollar": {
    "code": "JMD",
    "symbol": "J$"
  },
  "Jordanian Dinar": {
    "code": "JOD",
    "symbol": ""
  },
  "Yen": {
    "code": "JPY",
    "symbol": "¥"
  },
  "Kenyan Shilling": {
    "code": "KES",
    "symbol": ""
  },
  "Som": {
    "code": "KGS",
    "symbol": "лв"
  },
  "Riel": {
    "code": "KHR",
    "symbol": "៛"
  },
  "Comoro Franc": {
    "code": "KMF",
    "symbol": ""
  },
  "North Korean Won": {
    "code": "KPW",
    "symbol": "₩"
  },
  "Won": {
    "code": "KRW",
    "symbol": "₩"
  },
  "Kuwaiti Dinar": {
    "code": "KWD",
    "symbol": ""
  },
  "Cayman Islands Dollar": {
    "code": "KYD",
    "symbol": "$"
  },
  "Tenge": {
    "code": "KZT",
    "symbol": "лв"
  },
  "Kip": {
    "code": "LAK",
    "symbol": "₭"
  },
  "Lebanese Pound": {
    "code": "LBP",
    "symbol": "£"
  },
  "Sri Lanka Rupee": {
    "code": "LKR",
    "symbol": "₨"
  },
  "Liberian Dollar": {
    "code": "LRD",
    "symbol": "$"
  },
  "Lithuanian Litas": {
    "code": "LTL",
    "symbol": "Lt"
  },
  "Latvian Lats": {
    "code": "LVL",
    "symbol": "Ls"
  },
  "Libyan Dinar": {
    "code": "LYD",
    "symbol": ""
  },
  "Moroccan Dirham": {
    "code": "MAD",
    "symbol": ""
  },
  "Moldovan Leu": {
    "code": "MDL",
    "symbol": ""
  },
  "Malagasy Ariary": {
    "code": "MGA",
    "symbol": ""
  },
  "Denar": {
    "code": "MKD",
    "symbol": "ден"
  },
  "Kyat": {
    "code": "MMK",
    "symbol": ""
  },
  "Tugrik": {
    "code": "MNT",
    "symbol": "₮"
  },
  "Pataca": {
    "code": "MOP",
    "symbol": ""
  },
  "Ouguiya": {
    "code": "MRO",
    "symbol": ""
  },
  "Mauritius Rupee": {
    "code": "MUR",
    "symbol": "₨"
  },
  "Rufiyaa": {
    "code": "MVR",
    "symbol": ""
  },
  "Kwacha": {
    "code": "MWK",
    "symbol": ""
  },
  "Mexican Peso": {
    "code": "MXN",
    "symbol": "$"
  },
  "Malaysian Ringgit": {
    "code": "MYR",
    "symbol": "RM"
  },
  "Metical": {
    "code": "MZN",
    "symbol": "MT"
  },
  "Naira": {
    "code": "NGN",
    "symbol": "₦"
  },
  "Cordoba Oro": {
    "code": "NIO",
    "symbol": "C$"
  },
  "Norwegian Krone": {
    "code": "NOK",
    "symbol": "kr"
  },
  "Nepalese Rupee": {
    "code": "NPR",
    "symbol": "₨"
  },
  "New Zealand Dollar": {
    "code": "NZD",
    "symbol": "$"
  },
  "Rial Omani": {
    "code": "OMR",
    "symbol": "﷼"
  },
  "Balboa": {
    "code": "PAB",
    "symbol": "B/."
  },
  "Nuevo Sol": {
    "code": "PEN",
    "symbol": "S/."
  },
  "Kina": {
    "code": "PGK",
    "symbol": ""
  },
  "Philippine Peso": {
    "code": "PHP",
    "symbol": "Php"
  },
  "Pakistan Rupee": {
    "code": "PKR",
    "symbol": "₨"
  },
  "Zloty": {
    "code": "PLN",
    "symbol": "zł"
  },
  "Guarani": {
    "code": "PYG",
    "symbol": "Gs"
  },
  "Qatari Rial": {
    "code": "QAR",
    "symbol": "﷼"
  },
  "New Leu": {
    "code": "RON",
    "symbol": "lei"
  },
  "Serbian Dinar": {
    "code": "RSD",
    "symbol": "Дин."
  },
  "Russian Ruble": {
    "code": "RUB",
    "symbol": "руб"
  },
  "Rwanda Franc": {
    "code": "RWF",
    "symbol": ""
  },
  "Saudi Riyal": {
    "code": "SAR",
    "symbol": "﷼"
  },
  "Solomon Islands Dollar": {
    "code": "SBD",
    "symbol": "$"
  },
  "Seychelles Rupee": {
    "code": "SCR",
    "symbol": "₨"
  },
  "Sudanese Pound": {
    "code": "SDG",
    "symbol": ""
  },
  "Swedish Krona": {
    "code": "SEK",
    "symbol": "kr"
  },
  "Singapore Dollar": {
    "code": "SGD",
    "symbol": "$"
  },
  "Saint Helena Pound": {
    "code": "SHP",
    "symbol": "£"
  },
  "Leone": {
    "code": "SLL",
    "symbol": ""
  },
  "Somali Shilling": {
    "code": "SOS",
    "symbol": "S"
  },
  "Surinam Dollar": {
    "code": "SRD",
    "symbol": "$"
  },
  "Dobra": {
    "code": "STN",
    "symbol": "Db"
  },
  "El Salvador Colon": {
    "code": "SVC",
    "symbol": "₡"
  },
  "Syrian Pound": {
    "code": "SYP",
    "symbol": "£"
  },
  "Lilangeni": {
    "code": "SZL",
    "symbol": ""
  },
  "Baht": {
    "code": "THB",
    "symbol": "฿"
  },
  "Somoni": {
    "code": "TJS",
    "symbol": ""
  },
  "Manat": {
    "code": "TMT",
    "symbol": ""
  },
  "Tunisian Dinar": {
    "code": "TND",
    "symbol": ""
  },
  "Pa'anga": {
    "code": "TOP",
    "symbol": ""
  },
  "Turkish Lira": {
    "code": "TRY",
    "symbol": "₺"
  },
  "Trinidad and Tobago Dollar": {
    "code": "TTD",
    "symbol": "TT$"
  },
  "New Taiwan Dollar": {
    "code": "TWD",
    "symbol": "NT$"
  },
  "Tanzanian Shilling": {
    "code": "TZS",
    "symbol": ""
  },
  "Hryvnia": {
    "code": "UAH",
    "symbol": "₴"
  },
  "Uganda Shilling": {
    "code": "UGX",
    "symbol": ""
  },
  "US Dollar": {
    "code": "USD",
    "symbol": "$"
  },
  "Peso Uruguayo": {
    "code": "UYU",
    "symbol": "$U"
  },
  "Uzbekistan Sum": {
    "code": "UZS",
    "symbol": "лв"
  },
  "Bolivar Fuerte": {
    "code": "VEF",
    "symbol": "Bs"
  },
  "Dong": {
    "code": "VND",
    "symbol": "₫"
  },
  "Vatu": {
    "code": "VUV",
    "symbol": ""
  },
  "Tala": {
    "code": "WST",
    "symbol": ""
  },
  "CFA Franc BEAC": {
    "code": "XAF",
    "symbol": ""
  },
  "Silver": {
    "code": "XAG",
    "symbol": ""
  },
  "Gold": {
    "code": "XAU",
    "symbol": ""
  },
  "Bond Markets Units European Composite Unit (EURCO)": {
    "code": "XBA",
    "symbol": ""
  },
  "European Monetary Unit (E.M.U.-6)": {
    "code": "XBB",
    "symbol": ""
  },
  "European Unit of Account 9(E.U.A.-9)": {
    "code": "XBC",
    "symbol": ""
  },
  "European Unit of Account 17(E.U.A.-17)": {
    "code": "XBD",
    "symbol": ""
  },
  "East Caribbean Dollar": {
    "code": "XCD",
    "symbol": "$"
  },
  "SDR": {
    "code": "XDR",
    "symbol": ""
  },
  "UIC-Franc": {
    "code": "XFU",
    "symbol": ""
  },
  "CFA Franc BCEAO": {
    "code": "XOF",
    "symbol": ""
  },
  "Palladium": {
    "code": "XPD",
    "symbol": ""
  },
  "CFP Franc": {
    "code": "XPF",
    "symbol": ""
  },
  "Platinum": {
    "code": "XPT",
    "symbol": ""
  },
  "Codes specifically reserved for testing purposes": {
    "code": "XTS",
    "symbol": ""
  },
  "Yemeni Rial": {
    "code": "YER",
    "symbol": "﷼"
  },
  "Rand": {
    "code": "ZAR",
    "symbol": "R"
  },
  "Lesotho Loti": {
    "code": "LSL",
    "symbol": ""
  },
  "Namibia Dollar": {
    "code": "NAD",
    "symbol": "N$"
  },
  "Zambian Kwacha": {
    "code": "ZMK",
    "symbol": ""
  },
  "Zimbabwe Dollar": {
    "code": "ZWL",
    "symbol": ""
  }
};
