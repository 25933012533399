module["exports"] = [
  "Chevrolet",
  "Cadillac",
  "Ford",
  "Chrysler",
  "Dodge",
  "Jeep",
  "Tesla",
  "Toyota",
  "Honda",
  "Nissan",
  "Audi",
  "Mercedes Benz",
  "BMW",
  "Volkswagen",
  "Porsche",
  "Jaguar",
  "Aston Martin",
  "Land Rover",
  "Bentley",
  "Mini",
  "Rolls Royce",
  "Fiat",
  "Lamborghini",
  "Maserati",
  "Ferrari",
  "Bugatti",
  "Kia",
  "Hyundai"
];
