module["exports"] = [
    "Aleksi",
    "Antero",
    "Antti",
    "Ari",
    "Eero",
    "Ensio",
    "Erik",
    "Erkki",
    "Hannu",
    "Heikki",
    "Henrik",
    "Ilmari",
    "Jaakko",
    "Janne",
    "Jari",
    "Johannes",
    "Juha",
    "Juhani",
    "Juho",
    "Jukka",
    "Kalervo",
    "Kalevi",
    "Kari",
    "Kristian",
    "Lauri",
    "Markku",
    "Marko",
    "Markus",
    "Martti",
    "Matias",
    "Matti",
    "Mika",
    "Mikael",
    "Mikko",
    "Olavi",
    "Oskari",
    "Pekka",
    "Pentti",
    "Petri",
    "Petteri",
    "Sakari",
    "Sami",
    "Seppo",
    "Tapani",
    "Tapio",
    "Timo",
    "Tuomas",
    "Valtteri",
    "Veikko",
    "Ville",
    "Aino",
    "Anja",
    "Anna",
    "Anne",
    "Anneli",
    "Annikki",
    "Eeva",
    "Elina",
    "Elisabet",
    "Emilia",
    "Eveliina",
    "Hanna",
    "Hannele",
    "Helena",
    "Inkeri",
    "Irmeli",
    "Johanna",
    "Kaarina",
    "Karoliina",
    "Katariina",
    "Kristiina",
    "Kyllikki",
    "Laura",
    "Leena",
    "Liisa",
    "Maarit",
    "Maija",
    "Mari",
    "Maria",
    "Marika",
    "Marja",
    "Marjatta",
    "Minna",
    "Orvokki",
    "Pauliina",
    "Pirjo",
    "Pirkko",
    "Päivi",
    "Riitta",
    "Ritva",
    "Sari",
    "Satu",
    "Sinikka",
    "Sofia",
    "Susanna",
    "Tarja",
    "Tellervo",
    "Tiina",
    "Tuula",
    "Tuulikki"
];