module["exports"] = [
    "Aaltonen",
    "Ahonen",
    "Anttila",
    "Hakala",
    "Heikkilä",
    "Heikkinen",
    "Heinonen",
    "Hiltunen",
    "Hirvonen",
    "Hämäläinen",
    "Jokinen",
    "Järvinen",
    "Kallio",
    "Karjalainen",
    "Kinnunen",
    "Koivisto",
    "Korhonen",
    "Koskinen",
    "Laakso",
    "Laaksonen",
    "Lahtinen",
    "Laine",
    "Laitinen",
    "Lehtinen",
    "Lehto",
    "Lehtonen",
    "Leinonen",
    "Leppänen",
    "Manninen",
    "Mattila",
    "Miettinen",
    "Mustonen",
    "Mäkelä",
    "Mäkinen",
    "Niemi",
    "Nieminen",
    "Ojala",
    "Pitkänen",
    "Rantanen",
    "Räsänen",
    "Saarinen",
    "Salminen",
    "Salo",
    "Salonen",
    "Savolainen",
    "Toivonen",
    "Tuominen",
    "Turunen",
    "Virtanen",
    "Väisänen"
]