module.exports = [
	"Aaron",
	"Abdon",
	"Abel",
	"Abélard",
	"Abelin",
	"Abondance",
	"Abraham",
	"Absalon",
	"Acace",
	"Achaire",
	"Achille",
	"Adalard",
	"Adalbald",
	"Adalbéron",
	"Adalbert",
	"Adalric",
	"Adam",
	"Adegrin",
	"Adel",
	"Adelin",
	"Adelphe",
	"Adenet",
	"Adéodat",
	"Adhémar",
	"Adjutor",
	"Adolphe",
	"Adonis",
	"Adrien",
	"Agapet",
	"Agathange",
	"Agathon",
	"Agilbert",
	"Agnan",
	"Agrippin",
	"Aimable",
	"Aimé",
	"Alain",
	"Alban",
	"Albéric",
	"Albert",
	"Alcibiade",
	"Alcide",
	"Alcime",
	"Aldonce",
	"Aldric",
	"Aleaume",
	"Alexandre",
	"Alexis",
	"Alix",
	"Alliaume",
	"Almine",
	"Almire",
	"Aloïs",
	"Alphée",
	"Alphonse",
	"Alpinien",
	"Alverède",
	"Amalric",
	"Amandin",
	"Amant",
	"Ambroise",
	"Amédée",
	"Amélien",
	"Amiel",
	"Amour",
	"Anaël",
	"Anastase",
	"Anatole",
	"Ancelin",
	"Andéol",
	"Andoche",
	"André",
	"Ange",
	"Angilbe",
	"Angilran",
	"Angoustan",
	"Anicet",
	"Anne",
	"Annibal",
	"Ansbert",
	"Anselme",
	"Anthelme",
	"Antide",
	"Antoine",
	"Antonin",
	"Apollinaire",
	"Aquilin",
	"Arcade",
	"Archambaud",
	"Archange",
	"Archibald",
	"Arian",
	"Ariel",
	"Ariste",
	"Aristide",
	"Armand",
	"Armel",
	"Armin",
	"Arnould",
	"Arnaud",
	"Arolde",
	"Arsène",
	"Arsinoé",
	"Arthaud",
	"Arthème",
	"Arthur",
	"Ascelin",
	"Athanase",
	"Aubry",
	"Audebert",
	"Audouin",
	"Audran",
	"Auguste",
	"Aurèle",
	"Aurian",
	"Auxence",
	"Axel",
	"Aymard",
	"Aymeric",
	"Aymon",
	"Balthazar",
	"Baptiste",
	"Barnabé",
	"Barthélemy",
	"Bartimée",
	"Basile",
	"Bastien",
	"Baudouin",
	"Bénigne",
	"Benjamin",
	"Benoît",
	"Béranger",
	"Bérard",
	"Bernard",
	"Bertrand",
	"Blaise",
	"Bohémond",
	"Bon",
	"Boniface",
	"Bouchard",
	"Briac",
	"Brice",
	"Brieuc",
	"Bruno",
	"Calixte",
	"Camélien",
	"Camille",
	"Camillien",
	"Candide",
	"Caribert",
	"Carloman",
	"Cassandre",
	"Cassien",
	"Cédric",
	"Céleste",
	"Célestin",
	"Célien",
	"Césaire",
	"César",
	"Charles",
	"Charlemagne",
	"Childebert",
	"Chilpéric",
	"Chrétien",
	"Christian",
	"Christodule",
	"Christophe",
	"Chrysole",
	"Chrysostome",
	"Clarence",
	"Claude",
	"Claudien",
	"Cléandre",
	"Clément",
	"Cléry",
	"Clotaire",
	"Clovis",
	"Colin",
	"Côme",
	"Constance",
	"Constant",
	"Constantin",
	"Corentin",
	"Cyprien",
	"Cyriaque",
	"Cyrille",
	"Damien",
	"Daniel",
	"David",
	"Delphin",
	"Denis",
	"Désiré",
	"Didier",
	"Dieudonné",
	"Dimitri",
	"Dominique",
	"Dorian",
	"Edgard",
	"Edmond",
	"Édouard",
	"Éleuthère",
	"Élie",
	"Élisée",
	"Élzéar",
	"Émeric",
	"Émile",
	"Emmanuel",
	"Enguerrand",
	"Épiphane",
	"Éric",
	"Ernest",
	"Étienne",
	"Eubert",
	"Eudes",
	"Eudoxe",
	"Eugène",
	"Eusèbe",
	"Eustache",
	"Évariste",
	"Évrard",
	"Fabien",
	"Fabrice",
	"Falba",
	"Fantin",
	"Félicité",
	"Félix",
	"Ferdinand",
	"Fiacre",
	"Fidèle",
	"Firmin",
	"Flavien",
	"Flodoard",
	"Florent,",
	"Florestan",
	"Florian",
	"Fortuné",
	"Foulques",
	"Francisque",
	"François",
	"Frédéric",
	"Fulbert",
	"Fulcran",
	"Fulgence",
	"Gabin",
	"Gabriel",
	"Gaël",
	"Garnier",
	"Gaston",
	"Gaspar",
	"Gaspard",
	"Gatien",
	"Gaud",
	"Gautier",
	"Gédéon",
	"Geoffroy",
	"Georges",
	"Géraud",
	"Gérard",
	"Gerbert",
	"Germain",
	"Gervais",
	"Ghislain",
	"Gilbert",
	"Gilles",
	"Girart",
	"Gislebert",
	"Gondebaud",
	"Gonthier",
	"Gontran",
	"Gonzague",
	"Grégoire",
	"Guérin",
	"Gui",
	"Guillaume",
	"Gustave",
	"Guy",
	"Guyot",
	"Hardouin",
	"Hector",
	"Hédelin",
	"Hélier",
	"Henri",
	"Herbert",
	"Herluin",
	"Hervé",
	"Hilaire",
	"Hildebert",
	"Hincmar",
	"Hippolyte",
	"Honoré",
	"Hubert",
	"Hugues",
	"Innocent",
	"Isabeau",
	"Isidore",
	"Jacques",
	"Japhet",
	"Jason",
	"Jean",
	"Jeannel",
	"Jeannot",
	"Jérémie",
	"Jérôme",
	"Joachim",
	"Joanny",
	"Job",
	"Jocelyn",
	"Joël",
	"Johan",
	"Jonas",
	"Jonathan",
	"Joseph",
	"Josse",
	"Josselin",
	"Jourdain",
	"Jude",
	"Judicaël",
	"Jules",
	"Julien",
	"Juste",
	"Justin",
	"Lambert",
	"Landry",
	"Laurent",
	"Lazare",
	"Léandre",
	"Léon",
	"Léonard",
	"Léopold",
	"Leu",
	"Leufroy",
	"Libère",
	"Liétald",
	"Lionel",
	"Loïc",
	"Longin",
	"Lorrain",
	"Lothaire",
	"Louis",
	"Loup",
	"Luc",
	"Lucas",
	"Lucien",
	"Ludolphe",
	"Ludovic",
	"Macaire",
	"Malo",
	"Mamert",
	"Manassé",
	"Marc",
	"Marceau",
	"Marcel",
	"Marcelin",
	"Marius",
	"Martial",
	"Martin",
	"Mathurin",
	"Matthias",
	"Matthieu",
	"Maugis",
	"Maurice",
	"Maxence",
	"Maxime",
	"Maximilien",
	"Mayeul",
	"Médéric",
	"Melchior",
	"Mence",
	"Merlin",
	"Mérovée",
	"Michaël",
	"Michel",
	"Moïse",
	"Morgan",
	"Nathan",
	"Nathanaël",
	"Narcisse",
	"Naudet",
	"Néhémie",
	"Nestor",
	"Nicéphore",
	"Nicolas",
	"Noé",
	"Noël",
	"Norbert",
	"Normand",
	"Octave",
	"Odilon",
	"Odon",
	"Oger",
	"Olivier",
	"Oury",
	"Pacôme",
	"Palémon",
	"Parfait",
	"Pascal",
	"Paterne",
	"Patrice",
	"Paul",
	"Pépin",
	"Perceval",
	"Philémon",
	"Philibert",
	"Philippe",
	"Philothée",
	"Pie",
	"Pierre",
	"Pierrick",
	"Prosper",
	"Quentin",
	"Raoul",
	"Raphaël",
	"Raymond",
	"Régis",
	"Réjean",
	"Rémi",
	"Renaud",
	"René",
	"Reybaud",
	"Richard",
	"Robert",
	"Roch",
	"Rodolphe",
	"Rodrigue",
	"Roger",
	"Roland",
	"Romain",
	"Romuald",
	"Roméo",
	"Ronan",
	"Roselin",
	"Rachid",
	"Salomon",
	"Samuel",
	"Sauveur",
	"Savin",
	"Savinien",
	"Scholastique",
	"Sébastien",
	"Séraphin",
	"Serge",
	"Séverin",
	"Sidoine",
	"Sigebert",
	"Sigismond",
	"Silvère",
	"Simon",
	"Sixte",
	"Stanislas",
	"Stéphane",
	"Sylvain",
	"Sylvestre",
	"Tancrède",
	"Tanguy",
	"Taurin",
	"Théodore",
	"Théodose",
	"Théophile",
	"Théophraste",
	"Thibault",
	"Thibert",
	"Thierry",
	"Thomas",
	"Timoléon",
	"Timothée",
	"Titien",
	"Tonnin",
	"Toussaint",
	"Trajan",
	"Tristan",
	"Turold",
	"Tim",
	"Ulysse",
	"Urbain",
	"Valentin",
	"Valère",
	"Valéry",
	"Venance",
	"Venceslas",
	"Vianney",
	"Victor",
	"Victorien",
	"Victorin",
	"Vigile",
	"Vincent",
	"Vital",
	"Vivien",
	"Waleran",
	"Wandrille",
	"Xavier",
	"Xénophon",
	"Yves",
	"Yoann",
	"Zacharie",
	"Zaché",
	"Zéphirin"
]