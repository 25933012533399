module.exports = [
	"Abdonie",
	"Abeline",
	"Abigaelle",
	"Abigaïl",
	"Acacie",
	"Acanthe",
	"Adalbaude",
	"Adalsinde",
	"Adegrine",
	"Adélaïde",
	"Adèle",
	"Adélie",
	"Adeline",
	"Adeltrude",
	"Adolphie",
	"Adonise",
	"Adrastée",
	"Adrehilde",
	"Adrienne",
	"Agathe",
	"Agilberte",
	"Aglaé",
	"Agnane",
	"Agneflète",
	"Agnès",
	"Agrippine",
	"Aimée",
	"Alaine",
	"Alaïs",
	"Albane",
	"Albérade",
	"Alberte",
	"Alcidie",
	"Alcine",
	"Alcyone",
	"Aldegonde",
	"Aleth",
	"Alexandrine",
	"Alexanne",
	"Alexine",
	"Alice",
	"Aliénor",
	"Aliette",
	"Aline",
	"Alix",
	"Alizé",
	"Aloïse",
	"Aloyse",
	"Alphonsine",
	"Althée",
	"Amaliane",
	"Amalthée",
	"Amande",
	"Amandine",
	"Amante",
	"Amarande",
	"Amaranthe",
	"Amaryllis",
	"Ambre",
	"Ambroisie",
	"Améliane",
	"Amélie",
	"Ameline",
	"Améthyste",
	"Aminte",
	"Anaëlle",
	"Anaïs",
	"Anastasie",
	"Anatolie",
	"Anceline",
	"Andrée",
	"Anémone",
	"Angadrême",
	"Angèle",
	"Angeline",
	"Angélina",
	"Angélique",
	"Angilberte",
	"Anicée",
	"Anicette",
	"Annabelle",
	"Anne",
	"Annette",
	"Annonciade",
	"Ansberte",
	"Anstrudie",
	"Anthelmette",
	"Antigone",
	"Antoinette",
	"Antonine",
	"Aphélie",
	"Apolline",
	"Aquiline",
	"Arabelle",
	"Arcadie",
	"Archange",
	"Argine",
	"Ariane",
	"Aricie",
	"Ariel",
	"Arielle",
	"Arlette",
	"Armance",
	"Armande",
	"Armandine",
	"Armeline",
	"Armide",
	"Armelle",
	"Armine",
	"Arnaude",
	"Arsènie",
	"Arsinoé",
	"Artémis",
	"Arthurine",
	"Asceline",
	"Ascension",
	"Assomption",
	"Astarté",
	"Astérie",
	"Astrée",
	"Astride",
	"Athalie",
	"Athanasie",
	"Athénaïs",
	"Athina",
	"Aube",
	"Aubertine",
	"Aude",
	"Audeline",
	"Audrey",
	"Augustine",
	"Aure",
	"Aurélie",
	"Aurélienne",
	"Aurelle",
	"Auriane",
	"Aurore",
	"Auxane",
	"Aveline",
	"Avigaëlle",
	"Avoye",
	"Axeline",
	"Axelle",
	"Aymardine",
	"Aymonde",
	"Azalée",
	"Azélie",
	"Azeline",
	"Barbe",
	"Basilisse",
	"Bathilde",
	"Béatrice",
	"Bénédicte",
	"Bérangère",
	"Bernadette",
	"Berthe",
	"Bertille",
	"Betty",
	"Beuve",
	"Blanche",
	"Blandine",
	"Brigitte",
	"Brunehaut",
	"Brunehilde",
	"Camille",
	"Capucine",
	"Carine",
	"Caroline",
	"Cassandre",
	"Catherine",
	"Cécile",
	"Céleste",
	"Célestine",
	"Céline",
	"Chantal",
	"Charlaine",
	"Charline",
	"Charlotte",
	"Chloé",
	"Christelle",
	"Christiane",
	"Christine",
	"Claire",
	"Clara",
	"Claude",
	"Claudine",
	"Clarisse",
	"Clélie",
	"Clémence",
	"Clémentine",
	"Clio",
	"Clotilde",
	"Coline",
	"Conception",
	"Constance",
	"Coralie",
	"Coraline",
	"Corentine",
	"Corinne",
	"Cyrielle",
	"Danielle",
	"Daphné",
	"Débora",
	"Delphine",
	"Denise",
	"Diane",
	"Dieudonnée",
	"Dominique",
	"Doriane",
	"Dorine",
	"Dorothée",
	"Douce",
	"Édith",
	"Edmée",
	"Éléonore",
	"Éliane",
	"Élia",
	"Élisabeth",
	"Élise",
	"Ella",
	"Élodie",
	"Éloïse",
	"Elsa",
	"Émeline",
	"Émérance",
	"Émérencie",
	"Émilie",
	"Emma",
	"Emmanuelle",
	"Emmelie",
	"Ernestine",
	"Esther",
	"Estelle",
	"Eudoxie",
	"Eugénie",
	"Eulalie",
	"Euphrasie",
	"Eusébie",
	"Évangéline",
	"Eva",
	"Ève",
	"Évelyne",
	"Fanny",
	"Fantine",
	"Faustine",
	"Félicie",
	"Frédérique",
	"Flavie",
	"Fleur",
	"Flore",
	"Florence",
	"Florie",
	"Fortunée",
	"France",
	"Francette",
	"Francia",
	"Françoise",
	"Francine",
	"Gabrielle",
	"Gaëlle",
	"Garance",
	"Geneviève",
	"Georgette",
	"Gerberge",
	"Germaine",
	"Gertrude",
	"Gisèle",
	"Guenièvre",
	"Guilhemine",
	"Guillemette",
	"Gustavine",
	"Gwenaëlle",
	"Hélène",
	"Héloïse",
	"Henriette",
	"Hermine",
	"Hippolyte",
	"Honorine",
	"Hortense",
	"Huguette",
	"Inès",
	"Irène",
	"Irina",
	"Iris",
	"Isabeau",
	"Isabelle",
	"Iseult",
	"Ismérie",
	"Jacinthe",
	"Jacqueline",
	"Jade",
	"Janine",
	"Jeanne",
	"Jeanne d’Arc",
	"Jehanne",
	"Jocelyne",
	"Joëlle",
	"Joséphine",
	"Judith",
	"Julia",
	"Julie",
	"Juliette",
	"Justine",
	"Laura",
	"Laurane",
	"Laure",
	"Laureline",
	"Laurence",
	"Laurène",
	"Lauriane",
	"Laurine",
	"Léa",
	"Léna",
	"Léopoldine",
	"Léonie",
	"Léonne",
	"Lorraine",
	"Lucie",
	"Lucienne",
	"Lucille",
	"Ludivine",
	"Lydie",
	"Mégane",
	"Madeleine",
	"Magali",
	"Maguelone",
	"Mahaut",
	"Mallaury",
	"Manon",
	"Marceline",
	"Margot",
	"Marguerite",
	"Marianne",
	"Marie",
	"Marine",
	"Marion",
	"Marlène",
	"Marthe",
	"Martine",
	"Mathilde",
	"Maud",
	"Maureen",
	"Mauricette'",
	"Maxellende",
	"Maxime",
	"Mélanie",
	"Mélissa",
	"Mélissandre",
	"Mélisande",
	"Mélodie",
	"Michèle",
	"Mireille",
	"Miriam",
	"Moïsette",
	"Monique",
	"Morgane",
	"Muriel",
	"Mylène",
	"Nadège",
	"Nadine",
	"Nathalie",
	"Nicole",
	"Nine",
	"Noëlle",
	"Noémie",
	"Océane",
	"Odette",
	"Odile",
	"Olive",
	"Olympe",
	"Ombline",
	"Ophélie",
	"Oriande",
	"Oriane",
	"Orlane",
	"Ozanne",
	"Pascale",
	"Paule",
	"Paulette",
	"Pauline",
	"Priscille",
	"Pécine",
	"Pélagie",
	"Pénélope",
	"Perrine",
	"Pétronille",
	"Philippine",
	"Philomène",
	"Philothée",
	"Primerose",
	"Prudence",
	"Pulchérie",
	"Quentine",
	"Quiéta",
	"Quintia",
	"Rachel",
	"Raphaëlle",
	"Raymonde",
	"Rebecca",
	"Régine",
	"Reine",
	"Réjeanne",
	"Renée",
	"Rita",
	"Rolande",
	"Romane",
	"Rosalie",
	"Rose",
	"Roseline",
	"Sabine",
	"Salomé",
	"Sandra",
	"Sandrine",
	"Sarah",
	"Scholastique",
	"Ségolène",
	"Séverine",
	"Sibylle",
	"Simone",
	"Sixtine",
	"Solange",
	"Soline",
	"Sophie",
	"Stéphanie",
	"Suzanne",
	"Suzon",
	"Sylviane",
	"Sylvie",
	"Swassane",
	"Tatiana",
	"Thaïs",
	"Théodora",
	"Thérèse",
	"Tiphaine",
	"Ursule",
	"Valentine",
	"Valérie",
	"Véronique",
	"Victoire",
	"Vinciane",
	"Violette",
	"Virginie",
	"Viviane",
	"Xavière",
	"Yolande",
	"Ysaline",
	"Yseult",
	"Yvette",
	"Yvonne",
	"Zoé",
	"Zoéva"
]